import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

import "react-lazy-load-image-component/src/effects/blur.css";
import * as Styled from "./styles";
import { ImageProps } from "./types";

const Image: React.FC<ImageProps> = ({ src, alt }) => (
  <Styled.Image>
    <LazyLoadImage alt={alt || ""} src={src} effect="blur" />
  </Styled.Image>
);

export default Image;
