import { HashRouter, Switch, Route } from "react-router-dom";
import SimpleReactLightbox from "simple-react-lightbox";

import Header from "../components/Header";
import Footer from "../components/Footer";
import Navigation from "../components/Navigation";
import CookieBar from "../components/CookieBar";
import AddToHomescreenIos from "../components/AddToHomescreenIOS";
import { navigationMock } from "../components/Navigation/consts";
import WidgetProvider from "../setup/WidgetProvider";
import ThemeProvider from "../theme/ThemeProvider";
import ScrollToTop from "../components/ScrollToTop";
import { Inner } from "../components/styles/Inner";
import * as Styled from "../components/styles/Layout";

import { appRoutes } from "./consts";
import ServiceWorkerProvider from "../setup/ServiceWorkerProvider";

const Root = () => (
  <ThemeProvider>
    <SimpleReactLightbox>
      <HashRouter>
        <ScrollToTop>
          <Styled.Layout>
            <Styled.LayoutBody>
              <ServiceWorkerProvider>
                <WidgetProvider>
                  <Header />
                  <Switch>
                    {appRoutes.map((route) => (
                      <Route {...route} key={route.path} />
                    ))}
                  </Switch>
                  <Inner>
                    <Footer />
                  </Inner>
                  <Styled.LayoutNavigator>
                    <Navigation items={navigationMock} />
                  </Styled.LayoutNavigator>
                  <CookieBar
                    cookieExpire={120}
                    cookieName="dramatyczny-pwa-cookie"
                  />
                  <AddToHomescreenIos />
                </WidgetProvider>
              </ServiceWorkerProvider>
            </Styled.LayoutBody>
          </Styled.Layout>
        </ScrollToTop>
      </HashRouter>
    </SimpleReactLightbox>
  </ThemeProvider>
);

export default Root;
