import styled from "styled-components";

export const SpectacleList = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0;
`;

export const SpectacleListItem = styled.li`
  width: 100%;
  &:not(:last-child) {
    margin-bottom: 2rem;
  }
`;
