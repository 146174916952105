export const colors = {
  black: "#000",
  white: "#fff",
  red: "#ff0000",
  lightGrey: "#e4e4e4",
  lightGrey2: "#f2f2f2",
  grey: "#525252",
  grey2: "#a8a8a8",
  grey3: "#262626",
  grey4: "#707070"
};
