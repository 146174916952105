import LoaderSpinner from "react-loader-spinner";

import * as Styled from "../styles";

export const SpinnerLoader = () => (
  <Styled.LoaderSpinner>
    <Styled.LoaderOverlay>
      <LoaderSpinner
        type="TailSpin"
        color="currentColor"
        height={50}
        width={50}
      />
    </Styled.LoaderOverlay>
  </Styled.LoaderSpinner>
);
