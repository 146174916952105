import styled from "styled-components";
import { space } from "styled-system";

import { SectionStyledProps } from "./types";

export const Section = styled.section<SectionStyledProps>`
  ${space}
  width: 100%;
  color: ${({ theme }) => theme.colors.white};
`;

export const SectionHead = styled.div`
  text-transform: uppercase;
  margin-bottom: 1rem;
`;

export const SectionBody = styled.div``;
