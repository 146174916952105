import styled, { css } from "styled-components";

import { LoaderStyledProps } from "./types";

export const LoaderSpinner = styled.div``;

export const LoaderOverlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  pointer-events: none;
`;

export const LoaderContent = styled.div`
  width: 100%;
`;

export const LoaderListing = styled.div`
  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }
`;

export const LoaderHero = styled.div`
  width: 100%;
`;

export const Loader = styled.div<LoaderStyledProps>`
  ${({ isActive }) =>
    isActive &&
    css`
      pointer-events: none;
      z-index: 999;
      ${LoaderSpinner} {
        position: relative;
        padding: 6.125rem 0;
      }
    `}
`;
