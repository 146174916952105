import Typography from "../styles/Typography";
import Image from "../Image";

import * as Styled from "./styles";
import { SpectacleHeroProps } from "./types";

const SpectacleHero: React.FC<SpectacleHeroProps> = ({
  title,
  description: { heading, description },
  thumbnail
}) => (
  <Styled.SpectacleHero>
    {thumbnail.src && (
      <Styled.SpectacleHeroMedia>
        <Image {...thumbnail} />
      </Styled.SpectacleHeroMedia>
    )}
    <Styled.SpectacleHeroContent>
      <Styled.SpectacleHeroTitle>
        <span
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: title
          }}
        />
      </Styled.SpectacleHeroTitle>
      <Styled.SpectacleHeroDescription>
        <Typography variant="text4" as="span">
          {heading}{" "}
        </Typography>
        <Typography variant="heading3" as="span">
          {description}
        </Typography>
      </Styled.SpectacleHeroDescription>
    </Styled.SpectacleHeroContent>
  </Styled.SpectacleHero>
);

export default SpectacleHero;
