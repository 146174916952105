import { useState } from "react";
import { useQuery } from "react-query";
import axios from "axios";
import { useHistory, useLocation } from "react-router-dom";

import { routes } from "../routes";
import { API_URL, ENDPOINTS } from "../../api/consts";

import { PAGE_PARAM } from "./const";
import { PageProps } from "./types";

const fetchSpectacles = async (currentPage: number) => {
  const { data } = await axios.get(
    `${API_URL}/${ENDPOINTS.spectacles}/${currentPage}`
  );

  return data;
};

export const useSpectaclesQuery = (currentPage: number) => {
  const queryData = useQuery(
    `spectacles-${currentPage}`,
    () => fetchSpectacles(currentPage),
    {
      refetchOnWindowFocus: false
    }
  );

  return queryData;
};

export const useSearchPageParam = () => {
  const history = useHistory();

  const params = new URLSearchParams(history.location.search);
  const pageParam = params.get(PAGE_PARAM) || "1";

  return parseInt(pageParam, 10);
};

export const useSpectacles = () => {
  const history = useHistory();
  const { search, state } = useLocation();
  const initialPageNumber = useSearchPageParam();
  const [currentPage, setCurrentPage] = useState(initialPageNumber);

  const { data, isError, isLoading, isRefetching } =
    useSpectaclesQuery(currentPage);

  const paginationChange = (page: PageProps) => {
    const selectedPage = page.selected + 1;
    const pathname = routes.spectacles;
    const newSearch = `?${PAGE_PARAM}=${selectedPage}`;
    const newState = { detail: `page.selected +1-${selectedPage}` };

    if (search && state) {
      history.push({
        pathname: pathname,
        search: newSearch,
        state: newState
      });
    } else {
      history.replace({
        pathname,
        search: newSearch,
        state: newState
      });
    }

    setCurrentPage(selectedPage);
  };

  return {
    paginationChange,
    data,
    isError,
    isLoading,
    currentPage,
    isRefetching
  };
};
