import theme from "../theme";

export const LIGHTBOX_OPTIONS = {
  settings: {
    overlayColor: "rgba(0, 0, 0, 0.9)"
  },
  buttons: {
    backgroundColor: theme.colors.black,
    iconColor: theme.colors.white,
    iconPadding: "10px",
    showAutoplayButton: true,
    showCloseButton: true,
    showDownloadButton: false,
    showFullscreenButton: false,
    showNextButton: true,
    showPrevButton: true,
    showThumbnailsButton: false,
    size: "40px"
  },
  caption: {
    captionColor: theme.colors.white,
    captionTextTransform: "uppercase"
  },
  thumbnails: {
    showThumbnails: true,
    thumbnailsAlignment: "center",
    thumbnailsContainerBackgroundColor: "transparent",
    thumbnailsContainerPadding: "0",
    thumbnailsGap: "0 1px",
    thumbnailsIconColor: theme.colors.white,
    thumbnailsOpacity: 0.4,
    thumbnailsPosition: "bottom",
    thumbnailsSize: ["100px", "80px"]
  },
  progressBar: {
    backgroundColor: theme.colors.white,
    fillColor: theme.colors.black,
    height: "3px",
    showProgressBar: true
  }
};
