import { generatePath } from "react-router-dom";

import { routes } from "../../pages/routes";
import Typography from "../styles/Typography";
import ButtonInternal from "../Button/internal";
import Image from "../Image";

import * as Styled from "./styles";
import { SpectacleListItemProps } from "./types";

const SpectacleListItem: React.FC<SpectacleListItemProps> = ({
  id,
  title,
  description: { prefix, value },
  thumbnail
}) => (
  <Styled.SpectacleItem>
    <Styled.SpectacleItemBg>
      <Image {...thumbnail} />
    </Styled.SpectacleItemBg>
    <Styled.SpectacleItemContent>
      <Styled.SpectacleItemTitle>
        <span
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: title
          }}
        />
      </Styled.SpectacleItemTitle>
      <Styled.SpectacleItemDescription>
        <Typography variant="text4" as="span">
          {prefix}{" "}
        </Typography>
        <Typography variant="heading3" as="span">
          {value}
        </Typography>
      </Styled.SpectacleItemDescription>
      <Styled.SpectacleItemCta>
        <ButtonInternal
          to={generatePath(routes.spectacleDetails, { id })}
        >
          więcej
        </ButtonInternal>
      </Styled.SpectacleItemCta>
    </Styled.SpectacleItemContent>
  </Styled.SpectacleItem>
);

export default SpectacleListItem;
