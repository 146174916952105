import { useState } from "react";
import CookieConsent from "react-cookie-consent";

import { Inner } from "../styles/Inner";
import { CloseIcon, HomeIcon } from "../../assets/svg";
import { iOS } from "../../utils";

import * as Styled from "./styles";

const AddToHomescreenIos = () => {
  const [isVisible, setIsVisible] = useState(true);
  const windowNavigator = window.navigator;
  const lang = navigator.language;

  const isInstalled = (): boolean | undefined => {
    if (windowNavigator) {
      //@ts-ignore ddd
      if (windowNavigator.standalone) return true;

      return false;
    }

    return undefined;
  };

  return isVisible && iOS() === true && isInstalled() === false ? (
    <Styled.AddToHomescreen>
      <Inner>
        <CookieConsent
          location="none"
          buttonText={<CloseIcon />}
          containerClasses="addToHome"
          buttonWrapperClasses="addToHome__buttons"
          buttonClasses="addToHome__button"
          contentClasses="addToHome__content"
          cookieName="dramatyczny-ios-a2hs"
          expires={7}
          disableStyles
          onAccept={() => setIsVisible(false)}
        >
          <Styled.AddToHomescreenContainer>
            <Styled.AddToHomescreenBody>
              {lang === "pl-PL" ? (
                <>
                  <Styled.AddToHomescreenText>
                    Zainstaluj za darmo aplikację
                    <br />
                    na swoim iPhone.
                  </Styled.AddToHomescreenText>
                  <Styled.AddToHomescreenText>
                    Kliknij{" "}
                    <span>
                      <HomeIcon />
                    </span>{" "}
                    i dodaj ją
                    <br /> do ekranu początkowego
                  </Styled.AddToHomescreenText>
                </>
              ) : (
                <>
                  <Styled.AddToHomescreenText>
                    Install this web app on your
                    <br />
                    iPhone for free.
                  </Styled.AddToHomescreenText>
                  <Styled.AddToHomescreenText>
                    Tap{" "}
                    <span>
                      <HomeIcon />
                    </span>{" "}
                    and then Add
                    <br /> to Home Screen
                  </Styled.AddToHomescreenText>
                </>
              )}
            </Styled.AddToHomescreenBody>
          </Styled.AddToHomescreenContainer>
        </CookieConsent>
      </Inner>
    </Styled.AddToHomescreen>
  ) : null;
};

export default AddToHomescreenIos;
