import styled from "styled-components";

export const HeroSliderViewport = styled.div`
  overflow: hidden;
  width: 100%;
  cursor: move;
  cursor: grab;
`;

export const HeroSliderContainer = styled.div`
  display: flex;
  height: ${({ theme }) => theme.sizes.heroHeight};
`;

export const HeroSliderItem = styled.div`
  display: block;
  position: relative;
  min-width: 100%;
`;

export const HeroSliderPagination = styled.div`
  position: absolute;
  width: 100%;
  bottom: calc(${({ theme }) => theme.sizes.offset_big} * 4);
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const HeroSlider = styled.div`
  position: relative;
  width: 100%;
  max-width: ${({ theme }) => theme.sizes.inner_big};
  margin: 0 auto;
`;
export const HeroSliderCtaWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.colors.grey};
  padding: ${({ theme }) => theme.sizes.offset_big} 0;
  width: 100%;
`;

export const HeroSliderCta = styled.a`
  font-size: 0.75rem;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-family: ${({ theme }) => theme.fonts.archia};
  text-decoration: none;
`;
