import { useQuery } from "react-query";
import axios from "axios";

import { API_URL, ENDPOINTS } from "../../api/consts";

const fetchContacts = async () => {
  const { data } = await axios.get(
    `${API_URL}/${ENDPOINTS.contacts}`
  );

  return data;
};

export const useContacts = () => {
  const queryData = useQuery("contacts", fetchContacts);

  return queryData;
};
