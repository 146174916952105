import { ArrowLeftIcon, ArrowRightIcon } from "../../assets/svg";

import * as Styled from "./styles";
import {
  SliderNavButtonProps,
  SliderPaginationButtonProps
} from "./types";

export const DotButton: React.FC<SliderPaginationButtonProps> = ({
  selected,
  onClick
}) => <Styled.SliderDot isActive={selected} onClick={onClick} />;

export const PrevButton: React.FC<SliderNavButtonProps> = ({
  enabled,
  onClick
}) => (
  <Styled.SliderButton onClick={onClick} disabled={!enabled}>
    <ArrowLeftIcon />
  </Styled.SliderButton>
);

export const NextButton: React.FC<SliderNavButtonProps> = ({
  enabled,
  onClick
}) => (
  <Styled.SliderButton onClick={onClick} disabled={!enabled}>
    <ArrowRightIcon />
  </Styled.SliderButton>
);
