import styled, { css } from "styled-components";

const paginationItemStyles = css`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  border: 1px solid ${({ theme }) => theme.colors.grey4};
  margin-right: 0.5rem;
`;

export const Pagination = styled.div`
  .pagination {
    display: flex;
    font-size: 1.25rem;
    font-family: ${({ theme }) => theme.fonts.archia};
    text-decoration: none;
  }

  .pagination__item {
    ${paginationItemStyles}
    background: transparent;
    border-radius: 0;
    color: ${({ theme }) => theme.colors.white};
    cursor: pointer;
    &:nth-child(2) {
      margin-left: auto;
    }
    &:last-child {
      margin-left: auto;
      margin-right: 0;
    }

    &:hover,
    &.is-active {
      color: ${({ theme }) => theme.colors.red};
      border-color: ${({ theme }) => theme.colors.red};
      a {
        color: currentColor;
      }
    }

    &.is-disabled {
      color: ${({ theme }) => theme.colors.grey};
      border-color: ${({ theme }) => theme.colors.grey};
      opacity: 0.8;
    }

    &--nav {
      border: none;
      width: 1.25rem;
    }

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      text-decoration: none;
      font-weight: ${({ theme }) => theme.fontWeights.medium};
      color: currentColor;
    }
  }
`;
