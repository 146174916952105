import SpectacleListItem from "../SpectacleListItem";

import * as Styled from "./styles";
import { SpectacleListProps } from "./types";

const SpectacleList: React.FC<SpectacleListProps> = ({ items }) => (
  <Styled.SpectacleList>
    {items.map((item) => (
      <Styled.SpectacleListItem key={item.id}>
        <SpectacleListItem {...item} />
      </Styled.SpectacleListItem>
    ))}
  </Styled.SpectacleList>
);

export default SpectacleList;
