import { LogoIcon } from "../../assets/svg/index";
import { routes } from "../../pages/routes";

import * as Styled from "./styles";

const Logo = () => (
  <Styled.LogoWrapper to={routes.homepage}>
    <Styled.LogoSign>
      <LogoIcon />
    </Styled.LogoSign>
  </Styled.LogoWrapper>
);

export default Logo;
