import * as Styled from "./styles";
import { AccessibilityLabelProps } from "./types";

const AccessibilityLabel: React.FC<AccessibilityLabelProps> = ({
  children
}) => (
  <Styled.AccessibilityLabel>{children}</Styled.AccessibilityLabel>
);

export default AccessibilityLabel;
