const { REACT_APP_API_URL: API_URL_ENV } = process.env;

if (!API_URL_ENV) {
  throw new Error("API_URL environment variable is missing.");
}

export const API_URL: URL = new URL(
  API_URL_ENV,
  window.location.origin
);

export const ENDPOINTS = {
  heroSlides: "pwa_slides",
  contacts: "pwa_contacts",
  spectacleDetails: "pwa_show",
  spectacles: "pwa_shows"
};
