import styled from "styled-components";

import Typography from "../styles/Typography";

export const Footer = styled.footer`
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.black};
`;

export const FooterDescription = styled(Typography).attrs({
  variant: "text1",
  forwardedAs: "p"
})`
  color: ${({ theme }) => theme.colors.grey2};
  text-align: center;
`;
