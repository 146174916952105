import styled from "styled-components";

import Typography from "../styles/Typography";

export const MetaData = styled.div`
  &:not(:last-child) {
    margin-bottom: 1.75rem;
  }
`;

export const MetaDataHead = styled(Typography).attrs({
  variant: "text5",
  forwardedAs: "p"
})`
  color: ${({ theme }) => theme.colors.grey4};
`;

export const MetaDataDescription = styled.div`
  margin-bottom: 0.375rem;
`;

export const MetaDataAdditional = styled(Typography).attrs({
  variant: "text7",
  forwardedAs: "p"
})`
  color: ${({ theme }) => theme.colors.grey4};
  margin-top: 0.125rem;
`;

export const MetaDataPrice = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  &:not(:last-child) {
    margin-bottom: 0.5625rem;
  }
`;

export const MetaDataPriceItem = styled.div`
  display: inline-flex;
  flex-wrap: wrap;

  &:last-child {
    padding-left: 1rem;
    justify-content: flex-end;
    white-space: nowrap;
  }
`;
