import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

import { ButtonMode } from "./enums";
import { StyledButtonCommonProps } from "./types";

export const ButtonStyles = css<StyledButtonCommonProps>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 0.75rem;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-family: ${({ theme }) => theme.fonts.archia};
  height: 2.8125rem;
  border: 1px solid;
  padding: 1rem 2.9375rem;
  outline: none;
  transition: ${({ theme }) => theme.transitions.default};
  background-color: ${({ theme, mode = ButtonMode.Primary }) =>
    `${
      (mode === ButtonMode.Primary && "transparent") ||
      (mode === ButtonMode.Secondary && theme.colors.red)
    }`};

  color: ${({ theme }) => theme.colors.white};
  border-color: ${({ theme, mode = ButtonMode.Primary }) =>
    `${
      (mode === ButtonMode.Primary && theme.colors.white) ||
      (mode === ButtonMode.Secondary && theme.colors.red)
    }`};
  &:disabled,
  &[disabled] {
    pointer-events: none;
    opacity: 0.3;
  }
`;

export const ButtonLink = styled(Link)<StyledButtonCommonProps>`
  ${ButtonStyles}
  text-decoration: none;
`;

export const ButtonExternalLink = styled.a<StyledButtonCommonProps>`
  ${ButtonStyles}
  text-decoration: none;
`;
