import styled, { css } from "styled-components";

import { SliderPaginationStyledProps } from "./types";

export const SliderButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
  color: ${({ theme }) => theme.colors.grey4};
  border: 1px solid ${({ theme }) => theme.colors.grey4};
  background-color: ${({ theme }) => theme.colors.black};
  &:disabled {
    opacity: 0.3;
  }
`;

export const SliderDot = styled.button<SliderPaginationStyledProps>`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 50%;
  width: 0.375rem;
  height: 0.375rem;
  margin: 0 0.3125rem;

  ${({ isActive }) =>
    isActive &&
    css`
      width: 0.5rem;
      height: 0.5rem;
      background-color: ${({ theme }) => theme.colors.red};
    `}
`;
