import styled from "styled-components";

import Typography from "../styles/Typography";

export const TeamMemberBox = styled.a`
  position: relative;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.black};
  justify-content: space-between;
  text-decoration: none;
`;

export const TeamMemberBoxPhoto = styled.div`
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: auto;
  padding-top: 53%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
`;

export const TeamMemberBoxContent = styled.div`
  padding: 0.625rem ${({ theme }) => theme.sizes.offset} 1.875rem;
  border: 1px solid ${({ theme }) => theme.colors.grey4};
`;

export const TeamMemberBoxRole = styled(Typography).attrs({
  variant: "text5",
  forwardedAs: "p"
})`
  color: ${({ theme }) => theme.colors.grey4};
`;

export const TeamMemberBoxName = styled(Typography).attrs({
  variant: "heading2",
  forwardedAs: "p"
})`
  color: ${({ theme }) => theme.colors.white};
`;

export const TeamMemberBoxCaption = styled(Typography).attrs({
  variant: "text4",
  forwardedAs: "p"
})`
  color: ${({ theme }) => theme.colors.grey4};
`;
