const WIDGET_JS_URL = `${process.env.REACT_APP_WIDGET_URL}/static/js/main.js`;
const WIDGET_CSS_URL = `${process.env.REACT_APP_WIDGET_URL}/static/css/main.css`;

const timestamp = new Date().getTime();

export const injectWidget = (onLoad: () => void): void => {
  if (!document.getElementById("tf-widget-js")) {
    const script = document.createElement("script");

    script.src = `${WIDGET_JS_URL}?v=${timestamp}`;
    script.id = "tf-widget-js";
    script.async = true;
    script.onload = onLoad;

    document.body.appendChild(script);
  }
  if (!document.getElementById("tf-widget-css")) {
    const cssLink = document.createElement("link");

    cssLink.rel = "stylesheet";
    cssLink.id = "tf-widget-css";
    cssLink.setAttribute("href", `${WIDGET_CSS_URL}?v=${timestamp}`);

    document.head.appendChild(cssLink);
  }
};
