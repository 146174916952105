import { useQuery } from "react-query";
import axios from "axios";

import { API_URL, ENDPOINTS } from "../../api/consts";

const fetchSpectacleData = async (id: string) => {
  const { data } = await axios.get(
    `${API_URL}/${ENDPOINTS.spectacleDetails}/${id}`
  );

  return data;
};

export const useSpectacle = (id: string) => {
  const queryData = useQuery(
    `spectacle-${id}`,
    () => fetchSpectacleData(id),
    {
      refetchOnWindowFocus: false
    }
  );

  return queryData;
};
