import * as Styled from "./styles";
import { NavigationProps } from "./types";

const Navigation: React.FC<NavigationProps> = ({ items }) => (
  <Styled.Navigation>
    <Styled.NavigationList>
      {items.map(({ id, label, to, icon: Icon, exact }) => (
        <Styled.NavigationListItem key={id}>
          <Styled.NavigationLink {...{ to, exact }}>
            <Styled.NavigationLinkIcon>
              <Icon />
            </Styled.NavigationLinkIcon>
            <Styled.NavigationLinkLabel>
              {label}
            </Styled.NavigationLinkLabel>
          </Styled.NavigationLink>
        </Styled.NavigationListItem>
      ))}
    </Styled.NavigationList>
  </Styled.Navigation>
);

export default Navigation;
