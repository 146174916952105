import React, { useState, useCallback, useEffect } from "react";
import Loader from "../../components/Loader";
import { LoaderMode } from "../../components/Loader/enums";

import * as serviceWorker from "../../serviceWorkerRegistration";
import ServiceWorkerUpdate from "./ServiceWorkerUpdate";

const ServiceWorkerProvider: React.FC = ({ children }) => {
  const [isLoading, setLoading] = useState(false);
  const [registration, setRegistration] =
    useState<ServiceWorkerRegistration>();

  const loadNewVersion = useCallback(
    (waitingWorker: ServiceWorker | null) => {
      setLoading(true);
      waitingWorker?.postMessage({ type: "SKIP_WAITING" });
      window.location.reload();
    },
    []
  );

  const onUpdate = useCallback(
    (sw: ServiceWorkerRegistration) => {
      setRegistration(sw);
    },
    [loadNewVersion]
  );

  useEffect(() => {
    serviceWorker.register({ onUpdate });
  }, [onUpdate]);

  if (isLoading)
    return <Loader mode={LoaderMode.Primary} isLoading />;

  return (
    <>
      {!!registration && (
        <ServiceWorkerUpdate
          onAccept={() =>
            loadNewVersion(registration.waiting || null)
          }
          onReject={() => setRegistration(undefined)}
        />
      )}
      {children}
    </>
  );
};

export default ServiceWorkerProvider;
