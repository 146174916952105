import styled from "styled-components";

export const TeamList = styled.ul`
  display: flex;
  flex-direction: column;
`;

export const TeamListItem = styled.li`
  width: 100%;
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`;
