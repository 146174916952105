import Logo from "../Logo";
import AccessibilityLabel from "../AccessibilityLabel";
import { LogoTitleIcon } from "../../assets/svg/index";

import * as Styled from "./styles";

const Header = () => (
  <Styled.Header>
    <Styled.HeaderLogo>
      <AccessibilityLabel>Teatr Dramatyczny</AccessibilityLabel>
      <Logo />
    </Styled.HeaderLogo>
    <Styled.HeaderTitle>
      <LogoTitleIcon />
    </Styled.HeaderTitle>
  </Styled.Header>
);

export default Header;
