/* eslint-disable react/no-unstable-nested-components */
import ContentLoader from "react-content-loader";

import * as Styled from "../styles";
import theme from "../../../theme";

export const ListingLoader = () => {
  const Item = () => (
    <Styled.LoaderListing>
      <ContentLoader
        speed={3}
        width="100%"
        height="280"
        backgroundColor={theme.colors.grey3}
        foregroundColor={theme.colors.black}
      >
        <rect x="0" y="0" rx="0" ry="0" width="100%" height="280" />
      </ContentLoader>
    </Styled.LoaderListing>
  );

  return (
    <>
      <Item />
      <Item />
      <Item />
      <Item />
      <Item />
      <Item />
      <Item />
      <Item />
      <Item />
      <Item />
    </>
  );
};
