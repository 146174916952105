import styled from "styled-components";

import Typography from "../styles/Typography";

export const ContactBox = styled.div`
  color: ${({ theme }) => theme.colors.white};
  transition: 1s ease;
`;

export const ContactBoxBody = styled.div``;

export const ContactBoxBodyContent = styled.div``;

export const ContactBoxTitle = styled(Typography).attrs({
  variant: "heading5",
  forwardedAs: "h2"
})`
  margin-bottom: 1.5rem;
`;

export const ContactBoxContent = styled.div`
  font-family: ${({ theme }) => theme.fonts.archia};
  margin-bottom: 4rem;
`;

export const ContactBoxContentHeading = styled(Typography).attrs({
  variant: "heading5",
  forwardedAs: "p"
})`
  margin-bottom: 0.25rem;
`;

export const ContactBoxContentName = styled(Typography).attrs({
  variant: "heading2",
  forwardedAs: "p"
})`
  margin-bottom: 0.5rem;
`;

export const ContactBoxContentPosition = styled(Typography).attrs({
  variant: "text6",
  forwardedAs: "p"
})`
  margin-bottom: 0.5rem;
`;

export const ContactBoxContentText = styled.div`
  line-height: 1.8;
  font-weight: ${({ theme }) => theme.fontWeights.medium};

  br:not([class]) {
    content: "";
    display: block;
  }
`;

export const ContactBoxContentMail = styled.a`
  border-bottom: 1px dotted currentColor;
  padding: 0.125rem 0;
  text-decoration: none;
`;
