import styled from "styled-components";
import { ButtonStyles } from "../../components/Button/styles";
import { StyledButtonCommonProps } from "../../components/Button/types";

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  color: #ffffff;
  padding: 1.25rem;
`;

export const ModalWrapper = styled.div`
  padding: 3rem 2rem;
  background-color: #000;
  margin: auto;
`;
export const ModalTitle = styled.h3`
  font-size: 1.5rem;
  font-weight: 600;
`;
export const ModalText = styled.p`
  margin-top: 2rem;
`;
export const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 4rem;
  justify-content: center;
`;

export const Button = styled.button<StyledButtonCommonProps>`
  ${ButtonStyles}
  & + & {
    margin-left: 1rem;
  }
`;
