/* eslint-disable react/no-unstable-nested-components */
import ContentLoader from "react-content-loader";
import styled from "styled-components";

import theme from "../../../theme";
import { Inner } from "../../styles/Inner";

const DropdownTabsStyled = styled.div`
  width: 100%;
  margin-bottom: 9.375rem;
`;

const DropdownTabsHeadStyled = styled.div`
  margin-bottom: 32px;
`;

const DropdownTabsBodyStyled = styled.div``;

export const DropdownTabsLoader = () => {
  const DropdownTabsHead = () => (
    <DropdownTabsHeadStyled>
      <ContentLoader
        speed={3}
        width="100%"
        height={64}
        backgroundColor={theme.colors.grey3}
        foregroundColor={theme.colors.black}
      >
        <rect x="0" y="0" rx="0" ry="0" width="600" height="64" />
      </ContentLoader>
    </DropdownTabsHeadStyled>
  );

  const DropdownTabsBody = () => (
    <DropdownTabsBodyStyled>
      <Inner>
        <ContentLoader
          speed={3}
          width="100%"
          height={865}
          backgroundColor={theme.colors.grey3}
          foregroundColor={theme.colors.black}
        >
          <rect x="0" y="0" rx="0" ry="0" width="229" height="24" />
          <rect x="0" y="40" rx="0" ry="0" width="260" height="17" />
          <rect x="0" y="70" rx="0" ry="0" width="140" height="18" />
          <rect x="0" y="100" rx="0" ry="0" width="140" height="16" />
          <rect x="0" y="130" rx="0" ry="0" width="199" height="21" />
          <rect x="0" y="185" rx="0" ry="0" width="143" height="19" />
          <rect x="0" y="215" rx="0" ry="0" width="150" height="16" />
          <rect x="0" y="245" rx="0" ry="0" width="120" height="16" />
          <rect x="0" y="280" rx="0" ry="0" width="137" height="16" />
          <rect x="0" y="310" rx="0" ry="0" width="120" height="16" />
          <rect x="0" y="380" rx="0" ry="0" width="143" height="19" />
          <rect x="0" y="410" rx="0" ry="0" width="229" height="19" />
          <rect x="0" y="440" rx="0" ry="0" width="270" height="19" />
          <rect x="0" y="470" rx="0" ry="0" width="249" height="19" />
          <rect x="0" y="580" rx="0" ry="0" width="209" height="19" />
          <rect x="0" y="620" rx="0" ry="0" width="179" height="19" />
          <rect x="0" y="645" rx="0" ry="0" width="179" height="19" />
          <rect x="0" y="670" rx="0" ry="0" width="195" height="19" />
          <rect x="0" y="695" rx="0" ry="0" width="229" height="19" />
          <rect x="0" y="740" rx="0" ry="0" width="260" height="17" />
          <rect x="0" y="775" rx="0" ry="0" width="260" height="17" />
          <rect x="0" y="800" rx="0" ry="0" width="229" height="17" />
          <rect x="0" y="840" rx="0" ry="0" width="201" height="17" />
          <rect x="0" y="865" rx="0" ry="0" width="229" height="17" />
        </ContentLoader>
      </Inner>
    </DropdownTabsBodyStyled>
  );

  return (
    <DropdownTabsStyled>
      <DropdownTabsHead />
      <DropdownTabsBody />
    </DropdownTabsStyled>
  );
};
