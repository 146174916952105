/* eslint-disable react/no-unstable-nested-components */
import ContentLoader from "react-content-loader";
import styled from "styled-components";

import theme from "../../../theme";
import { Inner } from "../../styles/Inner";

const CalendarStyled = styled.div`
  width: 100%;
  margin-bottom: 9.375rem;
`;

const CalendarHeadStyled = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 3.25rem;
`;

const CalendarBodyListStyled = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 2.75rem;
  overflow: hidden;
`;

const CalendarBodyListItemStyled = styled.div`
  margin-right: 1rem;
`;

const CalendarListStyled = styled.div``;

export const CalendarLoader = () => {
  const CalendarHead = () => (
    <Inner>
      <CalendarHeadStyled>
        <ContentLoader
          speed={3}
          width={120}
          height={11}
          backgroundColor={theme.colors.grey3}
          foregroundColor={theme.colors.black}
        >
          <rect x="0" y="0" rx="0" ry="0" width="120" height="14" />
        </ContentLoader>
      </CalendarHeadStyled>
    </Inner>
  );

  const CalendarBodyItem = () => (
    <CalendarBodyListItemStyled>
      <ContentLoader
        speed={2}
        width={64}
        height={88}
        backgroundColor={theme.colors.grey3}
        foregroundColor={theme.colors.black}
      >
        <rect x="0" y="0" rx="0" ry="0" width="64" height="88" />
      </ContentLoader>
    </CalendarBodyListItemStyled>
  );

  const CalendarBodyList = () => (
    <CalendarBodyListStyled>
      {[...Array(31)].map((_, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <CalendarBodyItem key={`calendar-box-item-${i}`} />
      ))}
    </CalendarBodyListStyled>
  );

  const CalendarList = () => (
    <CalendarListStyled>
      <Inner>
        <ContentLoader
          speed={2}
          width="100%"
          height={600}
          backgroundColor={theme.colors.grey3}
          foregroundColor={theme.colors.black}
        >
          <rect x="0" y="0" rx="0" ry="0" width="600" height="280" />
          <rect
            x="0"
            y="305"
            rx="0"
            ry="0"
            width="600"
            height="280"
          />
        </ContentLoader>
      </Inner>
    </CalendarListStyled>
  );

  return (
    <CalendarStyled>
      <CalendarHead />
      <CalendarBodyList />
      <CalendarList />
    </CalendarStyled>
  );
};
