import { ChangeEvent, useState } from "react";

import { ArrowUpIcon, ArrowDownIcon } from "../../assets/svg";

import * as Styled from "./styles";
import { DropdownProps } from "./types";

const Dropdown = ({ options, onOptionChange }: DropdownProps) => {
  const [selectedOption, setSelectedOption] = useState(options[0].id);

  const onSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(e.target.value);
    if (onOptionChange) {
      onOptionChange(e.target.value);
    }
  };

  return (
    <Styled.SelectWrapper>
      <Styled.SelectControl>
        <Styled.SelectField
          onChange={onSelectChange}
          value={selectedOption}
        >
          {options.map(({ id, label }) => (
            <option key={id} value={id}>
              {label}
            </option>
          ))}
        </Styled.SelectField>
        <Styled.DecorArrows>
          <ArrowUpIcon />
          <ArrowDownIcon />
        </Styled.DecorArrows>
      </Styled.SelectControl>
    </Styled.SelectWrapper>
  );
};

export default Dropdown;
