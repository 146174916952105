import Typography from "../Typography";
import { Inner } from "../Inner";

import * as Styled from "./styles";
import { SectionProps } from "./types";

const Section: React.FC<SectionProps> = ({
  children,
  title,
  as,
  ...props
}) => (
  <Styled.Section {...{ as, ...props }}>
    {title && (
      <Styled.SectionHead>
        <Inner>
          <Typography as="h2" variant="text4">
            {title}
          </Typography>
        </Inner>
      </Styled.SectionHead>
    )}
    <Styled.SectionBody>{children}</Styled.SectionBody>
  </Styled.Section>
);

export default Section;
