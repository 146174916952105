import { routes } from "../../pages/routes";
import {
  RepertoireNavIcon,
  SpectaclesNavIcon,
  ContactNavIcon
} from "../../assets/svg";

export const navigationMock = [
  {
    id: "navigation-item-1",
    label: "repertuar",
    to: routes.homepage,
    icon: RepertoireNavIcon,
    exact: true
  },
  {
    id: "navigation-item-2",
    label: "spektakle",
    to: routes.spectacles,
    icon: SpectaclesNavIcon,
    exact: false
  },
  {
    id: "navigation-item-3",
    label: "kontakt",
    to: routes.contact,
    icon: ContactNavIcon,
    exact: true
  }
];
