import { useEffect } from "react";

import "swiper/swiper.min.css";
import Section from "../../components/styles/Section";
import Loader from "../../components/Loader";
import { LoaderMode } from "../../components/Loader/enums";
import { useWidget } from "../../setup/WidgetProvider/hooks";
import HeroSlider from "../../components/HeroSlider";

import { useHeroSlides } from "./hooks";

const Homepage = () => {
  const { data, isError, isLoading } = useHeroSlides();
  const { isLoaded } = useWidget();

  useEffect(() => {
    if (isLoaded) {
      window.PWA_HASH = window.location.hash;
      window.REACT_INJECTORS?.injectCalendarPWAWidget?.();
    }
  }, [isLoaded]);

  return (
    <>
      <Section pb="2rem">
        <Loader mode={LoaderMode.Hero} {...{ isLoading }}>
          {!isError
            ? data?.slides && <HeroSlider items={data.slides} />
            : "Ups. Coś poszło nie tak. Spróbuj ponownie później."}
        </Loader>
      </Section>

      <Section pb="4rem">
        <Loader
          mode={LoaderMode.Calendar}
          isLoading={!isLoaded}
          height={400}
        >
          <div id="calendar-pwa-widget" />
        </Loader>
      </Section>
    </>
  );
};

export default Homepage;
