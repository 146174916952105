import styled from "styled-components";

import Typography from "../styles/Typography";

export const Header = styled.header`
  display: flex;
  align-items: center;
  width: 100%;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.black};
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey3};
`;

export const HeaderLogo = styled(Typography).attrs({
  variant: "heading1",
  forwardedAs: "h1"
})`
  width: 5.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  height: 100%;
`;

export const HeaderTitle = styled.div`
  width: 100%;
  padding: 0.9375rem 1.375rem 0.625rem;
  display: flex;
  flex-direction: column;
  border-left: 1px solid ${({ theme }) => theme.colors.grey3};
`;
