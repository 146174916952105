import Homepage from "./Homepage/Homepage";
import Spectacles from "./Spectacles/Spectacles";
import SpectaclePage from "./SpectaclePage/SpectaclePage";
import Contact from "./Contact/Contact";
import { routes } from "./routes";
import { AppRouteProps } from "./types";

const { homepage, spectacles, spectacleDetails, contact } = routes;

export const appRoutes: AppRouteProps[] = [
  {
    path: homepage,
    component: Homepage,
    exact: true
  },
  {
    path: spectacles,
    component: Spectacles,
    exact: true
  },
  {
    path: spectacleDetails,
    component: SpectaclePage
  },
  {
    path: contact,
    component: Contact
  }
];
