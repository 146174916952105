import React from "react";
import { ThemeProvider as StyledThemeProvider } from "styled-components";

import GlobalStyle from "./GlobalStyles";

import theme from ".";

const ThemeProvider: React.FC = ({ children }) => (
  <StyledThemeProvider {...{ theme }}>
    <GlobalStyle />
    {children}
  </StyledThemeProvider>
);

export default ThemeProvider;
