import styled from "styled-components";

import { fieldReset } from "../../common/mixins";

export const SelectField = styled.select`
  ${fieldReset}
  min-height: 4rem;
  padding: 1rem 2.5rem 1rem 1rem;
  font-family: ${({ theme }) => theme.fonts.archia};
  color: ${({ theme }) => theme.colors.grey4};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  border: 1px solid ${({ theme }) => theme.colors.grey4};
  font-size: 1rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;

  &:focus,
  &.active {
    color: ${({ theme }) => theme.colors.lightGrey};
    border-color: ${({ theme }) => theme.colors.lightGrey};
  }

  &:focus + span,
  &.active + span {
    color: ${({ theme }) => theme.colors.lightGrey};
  }

  option {
    color: ${({ theme }) => theme.colors.black};
  }
`;

export const SelectWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.grey4};
`;

export const DecorArrows = styled.span`
  position: absolute;
  top: 0;
  right: 1.25rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 4rem;
  pointer-events: none;

  svg + svg {
    margin-top: 0.875rem;
  }
`;

export const SelectControl = styled.div`
  position: relative;
  width: 100%;
`;
