import { useEffect, useRef, useState, useCallback } from "react";
import useEmblaCarousel from "embla-carousel-react";

import HeroSlideBox from "../HeroSlideBox";
import { DotButton } from "../SliderButtons";
import { HERO_SLIDER_OPTIONS } from "../../cfg/heroslider";

import * as Styled from "./styles";
import { HeroSliderProps } from "./types";
import { MAX_NUM_OF_SLIDES } from "./cfg";

const HeroSlider: React.FC<HeroSliderProps> = ({ items }) => {
  const [viewportRef, embla] = useEmblaCarousel(HERO_SLIDER_OPTIONS);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState<number[]>([]);
  const heroSliderRef = useRef<HTMLDivElement>(null);

  const scrollTo = useCallback(
    (index) => embla && embla.scrollTo(index),
    [embla]
  );
  const onSelect = useCallback(() => {
    if (!embla) return;
    setSelectedIndex(embla.selectedScrollSnap());
  }, [embla, setSelectedIndex]);

  useEffect(() => {
    if (!embla) return;
    onSelect();
    setScrollSnaps(embla.scrollSnapList());
    embla.on("select", onSelect);
  }, [embla, setScrollSnaps, onSelect]);

  const toggleEmblaReady = useCallback(
    (event) => {
      if (heroSliderRef.current && embla) {
        const isResizeEvent = event === "resize";

        if (isResizeEvent) embla.reInit(HERO_SLIDER_OPTIONS);
      }
    },
    [embla]
  );

  useEffect(() => {
    if (embla) {
      embla.on("init", toggleEmblaReady);
      embla.on("resize", toggleEmblaReady);
      embla.on("reInit", toggleEmblaReady);
    }
  }, [embla, toggleEmblaReady]);

  return (
    <Styled.HeroSlider ref={heroSliderRef}>
      <Styled.HeroSliderViewport ref={viewportRef}>
        <Styled.HeroSliderContainer>
          {items
            .slice(0, MAX_NUM_OF_SLIDES)
            .map(({ id, link, ...restProps }) => (
              <Styled.HeroSliderItem key={id}>
                <HeroSlideBox {...{ link, ...restProps }} />
              </Styled.HeroSliderItem>
            ))}
        </Styled.HeroSliderContainer>
        <Styled.HeroSliderCtaWrapper>
          <Styled.HeroSliderCta
            href={items[selectedIndex].link.url}
            target="_blank"
          >
            czytaj więcej
          </Styled.HeroSliderCta>
        </Styled.HeroSliderCtaWrapper>
      </Styled.HeroSliderViewport>
      <Styled.HeroSliderPagination>
        {scrollSnaps.map((_, index) => (
          <DotButton
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            selected={index === selectedIndex}
            onClick={() => scrollTo(index)}
          />
        ))}
      </Styled.HeroSliderPagination>
    </Styled.HeroSlider>
  );
};

export default HeroSlider;
