import { LoaderMode } from "../../components/Loader/enums";
import DropdownTabs from "../../components/DropdownTabs";
import Section from "../../components/styles/Section";
import { Inner } from "../../components/styles/Inner";
import Loader from "../../components/Loader";

import { useContacts } from "./hooks";

const Contact = () => {
  const { data, isError, isLoading } = useContacts();

  return (
    <Section pt="1.25rem" pb="1.25rem">
      <Inner>
        <Loader mode={LoaderMode.DropdownTabs} {...{ isLoading }}>
          {!isError
            ? data?.contacts && (
                <DropdownTabs options={data.contacts} />
              )
            : "Error"}
        </Loader>
      </Inner>
    </Section>
  );
};

export default Contact;
