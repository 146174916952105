import ReactPaginate from "react-paginate";

import { ArrowLeftIcon, ArrowRightIcon } from "../../assets/svg";

import * as Styled from "./styles";
import { PaginationProps } from "./types";

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  itemsPerPage,
  totalItems,
  onChange
}) => {
  const pageCount = Math.ceil(totalItems / itemsPerPage);

  return (
    <Styled.Pagination>
      <ReactPaginate
        initialPage={currentPage}
        pageCount={pageCount}
        pageRangeDisplayed={1}
        marginPagesDisplayed={1}
        onPageChange={onChange}
        breakLabel="..."
        previousLabel={<ArrowLeftIcon />}
        nextLabel={<ArrowRightIcon />}
        containerClassName="pagination"
        breakClassName="pagination__item"
        pageClassName="pagination__item"
        previousClassName="pagination__item pagination__item--nav"
        nextClassName="pagination__item pagination__item--nav"
        disabledClassName="is-disabled"
        activeClassName="is-active"
      />
    </Styled.Pagination>
  );
};

export default Pagination;
