import styled from "styled-components";

export const Layout = styled.div`
  width: 100%;
  height: 100%;
`;

export const LayoutBody = styled.div`
  position: relative;
  color: ${({ theme }) => theme.colors.white};
  padding-bottom: calc(
    ${({ theme }) => theme.sizes.navigator} + 2rem
  );
  display: flex;
  flex-direction: column;
  height: calc(100% - ${({ theme }) => theme.sizes.navigator});
`;

export const LayoutNavigator = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: ${({ theme }) => theme.sizes.navigator};
  z-index: 999;
  background-color: ${({ theme }) => theme.colors.black};
`;
