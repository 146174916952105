import styled from "styled-components";

export const SliderNavigation = styled.div`
  display: flex;
`;

export const SliderNavigationItem = styled.div`
  &:not(:last-child) {
    margin-right: 1.5rem;
  }
`;
