/* eslint-disable camelcase */
import { useParams } from "react-router-dom";

import { useEffect } from "react";
import * as Styled from "./styles";
import { LoaderMode } from "../../components/Loader/enums";
import { useWidget } from "../../setup/WidgetProvider/hooks";
import { MetaDataProps } from "../../components/MetaData/types";
import Section from "../../components/styles/Section";
import { Inner } from "../../components/styles/Inner";
import SpectacleHero from "../../components/SpectacleHero";
import Wysiwyg from "../../components/Wysiwyg";
import MetaData from "../../components/MetaData";
import TeamList from "../../components/TeamList";
import Gallery from "../../components/Gallery";
import SpectacleList from "../../components/SpectacleList";
import Loader from "../../components/Loader";
import { useSpectacle } from "./hooks";

const SpectaclePage = () => {
  const { id } = useParams<{ id: string }>();
  const { isLoaded } = useWidget();

  const { data, isError, isFetching } = useSpectacle(id);
  const spectacle = data?.show[0];

  const {
    cast,
    description,
    description_text,
    gallery,
    featured,
    time,
    place,
    premiere,
    pricing,
    staff,
    thumbnail,
    title
  } = spectacle || {};

  useEffect(() => {
    if (!isFetching && spectacle?.booking_id && isLoaded) {
      window.PWA_HASH = window.location.hash;
      window.REACT_INJECTORS?.injectMiniScheduleWidgetPWA?.(
        spectacle.booking_id
      );

      const miniScheduleWidgetPwa = document.querySelector(
        "#mini-schedule-widget-pwa"
      );

      if (!miniScheduleWidgetPwa) {
        return;
      }

      const footer = document.querySelector<HTMLElement>("#footer");

      const mutationObserver = new MutationObserver(() => {
        if (footer) {
          footer.style.marginBottom = "40px";
        }
      });

      mutationObserver.observe(miniScheduleWidgetPwa, {
        childList: true,
        subtree: true
      });

      return () => {
        mutationObserver.disconnect();

        if (footer) {
          footer.style.marginBottom = "0px";
        }
      };
    }
  }, [spectacle, isFetching, isLoaded]);

  return (
    <Styled.SpectaclePage>
      <Section pt="1.25rem" as="div">
        <Inner>
          <Loader
            mode={LoaderMode.SpectacleDetails}
            isLoading={isFetching}
          >
            {!isError
              ? data?.show[0] && (
                  <>
                    <Section pb="1.25rem">
                      <SpectacleHero
                        title={title}
                        description={description}
                        thumbnail={thumbnail}
                      />
                    </Section>
                    <>
                      {time || place || premiere || pricing ? (
                        <Section pb="2.5rem">
                          <Inner>
                            <MetaData
                              heading="Daty:"
                              name="schedule-widget"
                            >
                              <div id="mini-schedule-widget-pwa" />
                            </MetaData>
                            {time ? <MetaData {...time} /> : null}
                            {place ? <MetaData {...place} /> : null}
                            {premiere ? (
                              <MetaData {...premiere} />
                            ) : null}
                            {pricing ? (
                              <MetaData {...pricing} />
                            ) : null}
                          </Inner>
                        </Section>
                      ) : null}
                      {description_text && (
                        <Section pb="2.875rem">
                          <Inner>
                            <Wysiwyg content={description_text} />
                          </Inner>
                        </Section>
                      )}
                      {staff.length ? (
                        <Section
                          title="REALIZATORZY:"
                          pt="1.25rem"
                          pb="1.25rem"
                        >
                          <Inner>
                            {staff.map((staffItem: MetaDataProps) => (
                              <MetaData
                                key={staffItem.id}
                                isAlternativeFont
                                {...staffItem}
                              />
                            ))}
                          </Inner>
                        </Section>
                      ) : null}
                      {cast.length ? (
                        <Section
                          title="OBSADA:"
                          pt="2.25rem"
                          pb="1.25rem"
                        >
                          <TeamList items={cast} />
                        </Section>
                      ) : null}
                      {gallery.length ? (
                        <Section pt="1.25rem" pb="2.625rem">
                          <Gallery title="GALERIA" items={gallery} />
                        </Section>
                      ) : null}
                      {featured.length ? (
                        <Section
                          title="Zobacz także"
                          pt="1.25rem"
                          pb="4rem"
                        >
                          <SpectacleList items={featured} />
                        </Section>
                      ) : null}
                    </>
                  </>
                )
              : null}
          </Loader>
        </Inner>
      </Section>
    </Styled.SpectaclePage>
  );
};

export default SpectaclePage;
