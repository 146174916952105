import Pagination from "../../components/Pagination";
import Loader from "../../components/Loader";
import { LoaderMode } from "../../components/Loader/enums";
import Section from "../../components/styles/Section";
import { Inner } from "../../components/styles/Inner";
import SpectacleList from "../../components/SpectacleList";

import { useSpectacles } from "./hooks";

const Spectacles = () => {
  const {
    isError,
    isLoading,
    data,
    currentPage,
    paginationChange,
    isRefetching
  } = useSpectacles();

  return (
    <Section as="div" pt="1.25rem">
      <Inner>
        <Loader mode={LoaderMode.Listing} {...{ isLoading }}>
          {!isError ? (
            <>
              <Section pb="3rem">
                <Loader
                  mode={LoaderMode.Listing}
                  isLoading={isRefetching}
                >
                  {data?.shows && (
                    <SpectacleList items={data.shows} />
                  )}
                </Loader>
              </Section>
              <Section pb="4rem">
                {data?.pagination && (
                  <Pagination
                    currentPage={currentPage - 1}
                    onChange={paginationChange}
                    itemsPerPage={data.pagination.posts_per_page}
                    totalItems={data.pagination.total}
                  />
                )}
              </Section>
            </>
          ) : (
            "Ups. Coś poszło nie tak. Spróbuj ponownie później."
          )}
        </Loader>
      </Inner>
    </Section>
  );
};

export default Spectacles;
