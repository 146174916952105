import styled from "styled-components";

export const Gallery = styled.div`
  width: 100%;
`;

export const GalleryHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  padding-left: ${({ theme }) => theme.sizes.offset};
`;

export const GalleryBody = styled.div`
  position: relative;
`;

export const GalleryBodyViewport = styled.div`
  overflow: hidden;
  width: 100%;
  cursor: move;
  cursor: grab;
`;

export const GalleryList = styled.div`
  display: flex;
  height: 17.5rem;
  user-select: none;
`;

export const GalleryListItem = styled.a`
  display: block;
  position: relative;
  min-width: 100%;
`;

export const GalleryListItemImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;

export const GalleryNavigator = styled.div`
  display: flex;
  margin-left: auto;
`;
