import React from "react";
import { ButtonMode } from "../../components/Button/enums";

import * as Styled from "./styles";
import { ServiceWorkerUpdateProps } from "./types";

const ServiceWorkerUpdate = ({
  onAccept,
  onReject
}: ServiceWorkerUpdateProps) => (
  <Styled.Wrapper>
    <Styled.ModalWrapper>
      <Styled.ModalTitle>
        Dostępna jest nowa wersja aplikacji.
      </Styled.ModalTitle>
      <Styled.ModalText>
        Czy chcesz zaktualizować ją teraz?
      </Styled.ModalText>
      <Styled.ButtonWrapper>
        <Styled.Button mode={ButtonMode.Secondary} onClick={onAccept}>
          Tak
        </Styled.Button>
        <Styled.Button mode={ButtonMode.Primary} onClick={onReject}>
          Nie
        </Styled.Button>
      </Styled.ButtonWrapper>
    </Styled.ModalWrapper>
  </Styled.Wrapper>
);

export default ServiceWorkerUpdate;
