import styled from "styled-components";

import Typography from "../styles/Typography";

export const SpectacleHero = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const SpectacleHeroMedia = styled.div`
  height: 17.5rem;
  width: 100%;
`;

export const SpectacleHeroContent = styled.div`
  padding: 1.25rem ${({ theme }) => theme.sizes.offset} 0.875rem;
`;

export const SpectacleHeroTitle = styled(Typography).attrs({
  variant: "heading4",
  forwardedAs: "h2"
})`
  margin-bottom: 0.25rem;
`;

export const SpectacleHeroDescription = styled.div``;
