import Typography from "../styles/Typography";

import * as Styled from "./styles";
import { MetaDataProps } from "./types";

const MetaData: React.FC<MetaDataProps> = ({
  heading,
  description,
  price,
  additionalInfo,
  isAlternativeFont,
  children
}) => (
  <Styled.MetaData>
    <Styled.MetaDataHead>{heading}</Styled.MetaDataHead>
    {description && (
      <Styled.MetaDataDescription>
        {isAlternativeFont ? (
          <Typography variant="heading3">{description}</Typography>
        ) : (
          <Typography variant="text4">{description}</Typography>
        )}
      </Styled.MetaDataDescription>
    )}
    {price &&
      price.map(({ id, type, value }) => (
        <Styled.MetaDataPrice key={id}>
          <Styled.MetaDataPriceItem>
            <Typography variant="text4">{type}</Typography>
          </Styled.MetaDataPriceItem>
          <Styled.MetaDataPriceItem>
            <Typography variant="text4">{value}</Typography>
          </Styled.MetaDataPriceItem>
        </Styled.MetaDataPrice>
      ))}
    {additionalInfo && (
      <Styled.MetaDataAdditional>
        {additionalInfo}
      </Styled.MetaDataAdditional>
    )}
    {children}
  </Styled.MetaData>
);

export default MetaData;
