import styled from "styled-components";

export const Image = styled.div`
  width: 100%;
  height: 100%;

  img,
  .lazy-load-image-background {
    width: 100%;
    height: 100%;
  }
  img {
    object-fit: cover;
    object-position: center;
  }
`;
