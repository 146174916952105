import { useState } from "react";

import { Inner } from "../styles/Inner";
import Dropdown from "../Dropdown";
import ContactBox from "../ContactBox";

import * as Styled from "./styles";
import { DropdownTabsProps } from "./types";

const DropdownTabs: React.FC<DropdownTabsProps> = ({ options }) => {
  const [activeOption, setActiveOption] = useState(options[0]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const updateContent = (element: any) => {
    const selectedOption = options.find((el) => el.id === element);

    if (selectedOption) {
      setActiveOption(selectedOption);
    }
  };

  return (
    <Styled.DropdownTabs>
      <Styled.DropdownTabsDropdown>
        <Dropdown {...{ options }} onOptionChange={updateContent} />
      </Styled.DropdownTabsDropdown>
      <Inner>
        <Styled.DropdownTabsContent>
          <ContactBox {...activeOption} />
        </Styled.DropdownTabsContent>
      </Inner>
    </Styled.DropdownTabs>
  );
};

export default DropdownTabs;
