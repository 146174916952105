import { useState } from "react";
import CookieConsent from "react-cookie-consent";

import { CloseIcon } from "../../assets/svg";

import * as Styled from "./styles";
import { CookieBarProps } from "./types";

const { REACT_APP_DRAMATYCZNY_URL: DRAMATYCZNY_URL = "" } =
  process.env;

const CookieBar: React.FC<CookieBarProps> = ({
  cookieName,
  cookieExpire
}) => {
  const [isCookieVisible, setIsCookieVisible] = useState(true);

  const closeCookieModal = () => setIsCookieVisible(false);

  return isCookieVisible ? (
    <Styled.CookieBar>
      <CookieConsent
        location="none"
        buttonText="akceptuję"
        buttonClasses="cookieConsent__button"
        contentClasses="cookiesConsent__content"
        buttonWrapperClasses="cookiesConsent__buttons"
        cookieName={cookieName}
        expires={cookieExpire}
        ariaAcceptLabel="Accept cookies"
        overlay
        overlayClasses="cookiesConsent__overlay"
        disableStyles
      >
        <Styled.CookieBarContent>
          <Styled.CookieBarHead>
            <Styled.CookieBarClose onClick={closeCookieModal}>
              <CloseIcon />
            </Styled.CookieBarClose>
          </Styled.CookieBarHead>
          <Styled.CookieBarBody>
            Ta aplikacja korzysta z plików Cookies w celu świadczenia
            usług na najwyższym poziomie. Dalsze korzystanie z
            aplikacji, oznacza zgodę na ich użycie. Więcej informacji
            o plikach Cookies, zakresie i celu przetwarzania danych
            znajduje się w{" "}
            <a
              href={`${DRAMATYCZNY_URL}/polityka-prywatnosci/`}
              target="_blank"
              rel="noreferrer"
            >
              polityce prywatności.
            </a>
          </Styled.CookieBarBody>
        </Styled.CookieBarContent>
      </CookieConsent>
    </Styled.CookieBar>
  ) : null;
};

export default CookieBar;
