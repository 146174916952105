import * as Styled from "./styles";

interface WysiwygProps {
  content: string;
}

const Wysiwyg: React.FC<WysiwygProps> = ({ content }) => (
  <Styled.Wysiwyg dangerouslySetInnerHTML={{ __html: content }} />
);

export default Wysiwyg;
