import { createContext } from "react";

export type WidgetContextType = {
  isLoaded: boolean;
};

const WidgetContext = createContext<WidgetContextType>({
  isLoaded: false
});

export default WidgetContext;
