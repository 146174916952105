import { colors } from "./config/colors";
import { fontWeights } from "./config/font-weights";
import { fonts } from "./config/fonts";
import { typography } from "./config/typography";
import { breakpoints, media } from "./config/media";
import "../assets/fonts/fonts.css";

const theme = {
  fonts,
  colors,
  fontWeights,
  typography,
  media,
  breakpoints,
  sizes: {
    inner: "37.5rem",
    inner_big: "75rem",
    navigator: "5.375rem",
    offset: "1rem",
    offset_big: "1.5rem",
    heroHeight: "28.125rem"
  },
  transitions: {
    default: "250ms ease-in-out"
  }
};

export default theme;

export type Theme = typeof theme;

export type WithTheme<T = unknown> = { theme: Theme } & T;
