/* eslint-disable react/no-unstable-nested-components */
import ContentLoader from "react-content-loader";
import styled from "styled-components";

import theme from "../../../theme";
import { Inner } from "../../styles/Inner";

const SpectacleStyled = styled.div`
  width: 100%;
  margin-bottom: 9.375rem;
`;

const SpectacleHeadStyled = styled.div`
  margin-bottom: 1.25rem;
`;

const SpectacleBodyStyled = styled.div``;

export const SpectacleDetailsLoader = () => {
  const SpectacleHead = () => (
    <SpectacleHeadStyled>
      <ContentLoader
        speed={3}
        width="100%"
        height={280}
        backgroundColor={theme.colors.grey3}
        foregroundColor={theme.colors.black}
      >
        <rect x="0" y="0" rx="0" ry="0" width="600" height="280" />
      </ContentLoader>
    </SpectacleHeadStyled>
  );

  const SpectacleBody = () => (
    <SpectacleBodyStyled>
      <Inner>
        <ContentLoader
          speed={3}
          width="100%"
          height={515}
          backgroundColor={theme.colors.grey3}
          foregroundColor={theme.colors.black}
        >
          <rect x="0" y="0" rx="0" ry="0" width="250" height="20" />
          <rect x="0" y="30" rx="0" ry="0" width="155" height="19" />
          <rect x="0" y="60" rx="0" ry="0" width="230" height="20" />
          <rect x="0" y="140" rx="0" ry="0" width="320" height="20" />
          <rect x="0" y="175" rx="0" ry="0" width="270" height="20" />
          <rect x="0" y="200" rx="0" ry="0" width="310" height="20" />
          <rect x="0" y="225" rx="0" ry="0" width="370" height="20" />
          <rect x="0" y="250" rx="0" ry="0" width="345" height="20" />
          <rect x="0" y="275" rx="0" ry="0" width="280" height="20" />
          <rect x="0" y="300" rx="0" ry="0" width="305" height="20" />
          <rect x="0" y="360" rx="0" ry="0" width="355" height="20" />
          <rect x="0" y="385" rx="0" ry="0" width="305" height="20" />
          <rect x="0" y="415" rx="0" ry="0" width="345" height="20" />
          <rect x="0" y="440" rx="0" ry="0" width="320" height="20" />
          <rect x="0" y="465" rx="0" ry="0" width="285" height="20" />
          <rect x="0" y="490" rx="0" ry="0" width="310" height="20" />
          <rect x="0" y="515" rx="0" ry="0" width="305" height="20" />
        </ContentLoader>
      </Inner>
    </SpectacleBodyStyled>
  );

  return (
    <SpectacleStyled>
      <SpectacleHead />
      <SpectacleBody />
    </SpectacleStyled>
  );
};
