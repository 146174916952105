import styled from "styled-components";

export const AccessibilityLabel = styled.span`
  position: absolute;
  left: -625rem;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
`;
