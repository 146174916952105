/* eslint-disable @typescript-eslint/no-shadow */
import * as Styled from "./styles";
import { ContactBoxProps } from "./types";

const ContactBox: React.FC<ContactBoxProps> = ({ data }) => (
  <Styled.ContactBox>
    <Styled.ContactBoxBody>
      {data &&
        data.map(({ id, heading, elements }) => (
          <Styled.ContactBoxBodyContent key={id}>
            {heading && (
              <Styled.ContactBoxTitle>
                {heading}
              </Styled.ContactBoxTitle>
            )}
            {elements &&
              elements.map(
                ({ id, heading, name, position, text, emails }) => (
                  <Styled.ContactBoxContent key={id}>
                    {heading && (
                      <Styled.ContactBoxContentHeading>
                        {heading}
                      </Styled.ContactBoxContentHeading>
                    )}
                    {name && (
                      <Styled.ContactBoxContentName>
                        {name}
                      </Styled.ContactBoxContentName>
                    )}
                    {position && (
                      <Styled.ContactBoxContentPosition>
                        {position}
                      </Styled.ContactBoxContentPosition>
                    )}
                    {text && (
                      <Styled.ContactBoxContentText
                        dangerouslySetInnerHTML={{
                          __html: text.replace(
                            /(?:\r\n|\r|\n)/g,
                            "<br />"
                          )
                        }}
                      />
                    )}
                    {emails &&
                      emails.map(({ id, email }) => (
                        <Styled.ContactBoxContentMail
                          key={id}
                          href={`mailto:${email}`}
                        >
                          {email}
                        </Styled.ContactBoxContentMail>
                      ))}
                  </Styled.ContactBoxContent>
                )
              )}
          </Styled.ContactBoxBodyContent>
        ))}
    </Styled.ContactBoxBody>
  </Styled.ContactBox>
);

export default ContactBox;
