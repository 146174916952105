import * as Styled from "./styles";
import { TeamMemberBoxProps } from "./types";

const TeamMemberBox: React.FC<TeamMemberBoxProps> = ({
  role,
  name,
  caption,
  thumbnail: { src: imgSrc },
  url
}) => (
  <Styled.TeamMemberBox href={url} target="_blank">
    {imgSrc && (
      <Styled.TeamMemberBoxPhoto
        style={{
          backgroundImage: `url(${imgSrc})`
        }}
      />
    )}
    <Styled.TeamMemberBoxContent>
      {role && (
        <Styled.TeamMemberBoxRole>{role}</Styled.TeamMemberBoxRole>
      )}
      <Styled.TeamMemberBoxName>{name}</Styled.TeamMemberBoxName>
      {caption && (
        <Styled.TeamMemberBoxCaption>
          {caption}
        </Styled.TeamMemberBoxCaption>
      )}
    </Styled.TeamMemberBoxContent>
  </Styled.TeamMemberBox>
);

export default TeamMemberBox;
