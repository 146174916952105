import TeamMemberBox from "../TeamMemberBox";

import * as Styled from "./styles";
import { TeamListProps } from "./types";

const TeamList: React.FC<TeamListProps> = ({ items }) => (
  <Styled.TeamList>
    {items.map(({ id, ...rest }) => (
      <Styled.TeamListItem key={id}>
        <TeamMemberBox {...rest} />
      </Styled.TeamListItem>
    ))}
  </Styled.TeamList>
);

export default TeamList;
