import React from "react";

import { StyledText } from "./styles";
import { TypographyProps } from "./types";

const Typography: React.FC<TypographyProps> = ({
  children,
  variant = "body",
  as,
  ...props
}) => (
  <StyledText {...{ variant, as, ...props }}>{children}</StyledText>
);

export default Typography;
