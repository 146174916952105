import React from "react";

import * as Styled from "./styles";
import { ButtonLinkProps } from "./types";
import { ButtonMode } from "./enums";

const ButtonInternal: React.FC<ButtonLinkProps> = ({
  mode = ButtonMode.Primary,
  isFullWidth,
  children,
  ...props
}) => (
  <Styled.ButtonLink {...{ mode, isFullWidth, ...props }}>
    {children}
  </Styled.ButtonLink>
);

export default ButtonInternal;
