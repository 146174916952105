import styled from "styled-components";

export const Wysiwyg = styled.div`
  width: 100%;
  font-family: ${({ theme }) => theme.fonts.archia};

  *:not([class]) {
    font-size: 1rem;
    line-height: 1.5;
    color: ${({ theme }) => theme.colors.white};
    word-break: break-word;
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    &:not(:last-child) {
      margin-bottom: 0.625rem;
    }
    br:not([class]) {
      margin-bottom: 0;
    }
  }

  a {
    text-decoration: underline;
  }

  img {
    max-width: 100%;
    height: auto;
    margin: 1rem 0;
  }
`;
