import styled from "styled-components";
import { NavLink } from "react-router-dom";

import Typography from "../styles/Typography";

export const Navigation = styled.nav`
  border-top: 1px solid ${({ theme }) => theme.colors.grey4};
  padding: 1.375rem 0;
  background-color: ${({ theme }) => theme.colors.black};
`;

export const NavigationList = styled.ul`
  display: flex;
`;

export const NavigationListItem = styled.li`
  flex: 1;
`;

export const NavigationLink = styled(NavLink).attrs({
  activeClassName: "is-active"
})`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${({ theme }) => theme.colors.grey4};
  transition: ${({ theme }) => theme.transitions.default};
  text-decoration: none;

  &.is-active {
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const NavigationLinkIcon = styled.div`
  margin-bottom: 0.25rem;
`;

export const NavigationLinkLabel = styled(Typography).attrs({
  variant: "text3",
  forwardedAs: "span"
})`
  text-transform: uppercase;
`;
