import styled from "styled-components";

import Typography from "../styles/Typography";

export const AddToHomescreen = styled.div`
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: ${({ theme }) => theme.colors.black};
  border-top: 1px solid ${({ theme }) => theme.colors.grey};
  z-index: 1000;

  .addToHome {
    position: relative;
  }

  .addToHome__content {
    position: relative;
  }

  .addToHome__buttons {
    position: absolute;
    top: 16px;
    right: 0;
  }

  .addToHome__button {
    background: ${({ theme }) => theme.colors.black};
    svg {
      width: 0.625rem;
      height: 0.625rem;
      color: ${({ theme }) => theme.colors.white};
    }
  }
`;

export const AddToHomescreenContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.white};
  padding: 0.75rem 0 1.125rem;
`;

export const AddToHomescreenHead = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 1rem;
`;

export const AddToHomescreenClose = styled.div`
  svg {
    width: 0.625rem;
    height: 0.625rem;
  }
`;

export const AddToHomescreenBody = styled.div`
  margin-top: 2rem;
`;

export const AddToHomescreenText = styled(Typography).attrs({
  variant: "text8",
  forwardedAs: "p"
})`
  &:not(:last-child) {
    margin-bottom: 1.25rem;
  }
  span {
    margin: 0 0.625rem;
  }
`;
