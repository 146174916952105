import ContentLoader from "react-content-loader";

import * as Styled from "../styles";
import theme from "../../../theme";

export const HeroLoader = () => (
  <Styled.LoaderHero>
    <ContentLoader
      speed={3}
      width="100%"
      height="450"
      backgroundColor={theme.colors.grey3}
      foregroundColor={theme.colors.black}
    >
      <rect x="0" y="0" rx="0" ry="0" width="100%" height="450" />
    </ContentLoader>
  </Styled.LoaderHero>
);
