import { fonts } from "./fonts";
import { fontWeights } from "./font-weights";

export const typography = {
  heading1: {
    fontSize: "1.125rem",
    lineHeight: 1.4,
    fontWeight: fontWeights.bold,
    fontFamily: fonts.nooks
  },
  heading2: {
    fontSize: "2rem",
    lineHeight: 1.25,
    fontWeight: fontWeights.bold,
    fontFamily: fonts.nooks
  },
  heading3: {
    fontSize: "1.125rem",
    lineHeight: 1.3,
    fontWeight: fontWeights.bold,
    fontFamily: fonts.nooks
  },
  heading4: {
    fontSize: "1.5rem",
    lineHeight: 1.25,
    fontWeight: fontWeights.heavy,
    fontFamily: fonts.nooks
  },
  heading5: {
    fontSize: "1.5rem",
    lineHeight: 1.3,
    fontWeight: fontWeights.medium,
    fontFamily: fonts.archia
  },
  text1: {
    fontSize: "0.75rem",
    lineHeight: 1.5,
    fontWeight: fontWeights.regular,
    fontFamily: fonts.archia
  },
  text2: {
    fontSize: "1rem",
    lineHeight: 1.2,
    fontWeight: fontWeights.medium,
    fontFamily: fonts.archia
  },
  text3: {
    fontSize: "0.5rem",
    lineHeight: 1.4,
    fontWeight: fontWeights.medium,
    fontFamily: fonts.archia
  },
  text4: {
    fontSize: "1rem",
    lineHeight: 1.5,
    fontWeight: fontWeights.medium,
    fontFamily: fonts.archia
  },
  text5: {
    fontSize: "0.75rem",
    lineHeight: 2.1,
    fontWeight: fontWeights.medium,
    fontFamily: fonts.archia
  },
  text6: {
    fontSize: "1rem",
    lineHeight: 1.8,
    fontWeight: fontWeights.medium,
    fontFamily: fonts.archia
  },
  text7: {
    fontSize: "0.75rem",
    lineHeight: 1.5,
    fontWeight: fontWeights.medium,
    fontFamily: fonts.archia
  },
  text8: {
    fontSize: "1rem",
    lineHeight: 1.5,
    fontWeight: fontWeights.regular,
    fontFamily: fonts.archia
  }
};
