import React, { useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";

import { injectWidget } from "../utils/inject-widgets";

import WidgetContext from "./context";

const WidgetProvider: React.FC = ({ children }) => {
  const scriptTag = document.getElementById("tf-widget-js");
  const [isLoaded, setIsLoaded] = useState(!!scriptTag);
  const history = useHistory();

  useEffect(() => {
    window.PWA_HISTORY = history;
  }, [history]);

  useEffect(() => {
    if (!isLoaded) {
      injectWidget(() => {
        setIsLoaded(true);

        window.PWA_HASH = window.location.hash;
      });
    }
  }, [isLoaded]);

  const value = useMemo(() => ({ isLoaded }), [isLoaded]);

  return (
    <WidgetContext.Provider value={value}>
      {children}
    </WidgetContext.Provider>
  );
};

export default WidgetProvider;
