import { useQuery } from "react-query";
import axios from "axios";

import { API_URL, ENDPOINTS } from "../../api/consts";

const fetchHeroSlides = async () => {
  const { data } = await axios.get(
    `${API_URL}/${ENDPOINTS.heroSlides}`
  );

  return data;
};

export const useHeroSlides = () =>
  useQuery("hero slides", fetchHeroSlides);
