import styled from "styled-components";
import { Link } from "react-router-dom";

export const LogoWrapper = styled(Link)`
  display: block;
`;

export const LogoSign = styled.span`
  display: flex;
  width: 100%;

  svg {
    width: 2.75rem;
    height: 2.1875rem;
    font-size: 2.1875rem;
  }
`;
