import React from "react";

import * as Styled from "./styles";
import { LoaderProps } from "./types";
import { LoaderMode } from "./enums";
import { SpinnerLoader } from "./components/Spinner";
import { ListingLoader } from "./components/Listing";
import { HeroLoader } from "./components/Hero";
import { DropdownTabsLoader } from "./components/DropdownTabs";
import { SpectacleDetailsLoader } from "./components/SpectacleDetails";
import { CalendarLoader } from "./components/Calendar";

const Loader: React.FC<LoaderProps> = ({
  mode = LoaderMode.Primary,
  isLoading,
  children,
  height
}) => (
  <Styled.Loader
    isActive={isLoading}
    style={{ minHeight: `${height}px` || "" }}
  >
    {isLoading ? (
      <>
        {mode === LoaderMode.Primary && <SpinnerLoader />}
        {mode === LoaderMode.Hero && <HeroLoader />}
        {mode === LoaderMode.Listing && <ListingLoader />}
        {mode === LoaderMode.DropdownTabs && <DropdownTabsLoader />}
        {mode === LoaderMode.SpectacleDetails && (
          <SpectacleDetailsLoader />
        )}
        {mode === LoaderMode.Calendar && <CalendarLoader />}
      </>
    ) : (
      <Styled.LoaderContent>{children}</Styled.LoaderContent>
    )}
  </Styled.Loader>
);

export default Loader;
