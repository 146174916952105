import { scroller } from "react-scroll";

export const iOS = () =>
  [
    "iPad Simulator",
    "iPhone Simulator",
    "iPod Simulator",
    "iPad",
    "iPhone",
    "iPod"
  ].includes(navigator.platform) ||
  (navigator.userAgent.includes("Mac") && "ontouchend" in document);

export const scrollToSection = (target: string, offsetVal = 0) =>
  scroller.scrollTo(target, {
    duration: 800,
    delay: 0,
    smooth: true,
    offset: offsetVal
  });
