import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";

import { colors } from "./config/colors";
import { fonts } from "./config/fonts";
import { fontWeights } from "./config/font-weights";

const GlobalStyle = createGlobalStyle`
  ${reset}
  html {
    box-sizing: border-box;
    font-size: 16px;
    margin: 0;
    padding: 0;
    min-height: 100%;
   
  }
  body {
    margin: 0;
    padding: 0;
    font-family: ${fonts.archia};
    line-height: 1.4;
    font-weight: 400;
    background-color: ${colors.black};
    min-height: 100vh;
  }
  #root {
    height: 100%;
  }
  p, h1, h2, h3, h4, h5, h6, blockquote, pre, ul, ol, li {
    user-select: none;
  }
  *,
  *:before,
  *:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: inherit;
  }
  svg {
    fill: currentColor;
  }
  a {
    color: ${colors.white};
    text-decoration: underline;
  }
  button {
    padding: 0;
    border: none;
    cursor: pointer;
  }
  iframe {
    width: 100%;
  }
  #SRLLightbox .SRLCaptionContainer {
      background-color: ${colors.black};
      padding: 1.25rem 0;
      justify-content: flex-start;
  }
  #SRLLightbox .SRLCaptionText {
    font-weight: ${fontWeights.medium};
    font-size: 1.125rem;
    font-family: ${fonts.nooks};
    text-transform: none;
    text-align: left;
  }
  #SRLLightbox .SRLCaptionText:before { 
    content: 'Autor zdjęć:'; 
    display: block;
    font-size: 0.625rem;
    color: ${colors.grey4};
    font-family: ${fonts.archia};
    line-height: 1.6;
  }
  .cookiesConsent__overlay { 
    position: fixed;
    background-color: rgba(0,0,0,0.53);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
  }
  .pwa-install-popup-ios {
    bottom: 0;
    animation: none;
    background-color: ${colors.black};
    width: 100%;
    left: 0;
    right: 0;
    border-radius: 0;
    border-top: 1px solid ${colors.grey};
    z-index: 1000;
  }
`;

export default GlobalStyle;
