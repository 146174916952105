/* eslint-disable camelcase */
import { useEffect, useState, useCallback } from "react";
import useEmblaCarousel from "embla-carousel-react";
import { SRLWrapper } from "simple-react-lightbox";

import SliderNavigation from "../SliderNavigation";
import Typography from "../styles/Typography";
import { LIGHTBOX_OPTIONS } from "../../cfg/lightbox";
import { GALLERY_OPTIONS } from "../../cfg/gallery";

import * as Styled from "./styles";
import { GalleryProps } from "./types";

const Gallery: React.FC<GalleryProps> = ({ title, items }) => {
  const [viewportRef, embla] = useEmblaCarousel(GALLERY_OPTIONS);
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);

  const scrollPrev = useCallback(
    () => embla && embla.scrollPrev(),
    [embla]
  );
  const scrollNext = useCallback(
    () => embla && embla.scrollNext(),
    [embla]
  );

  const onSelect = useCallback(() => {
    if (!embla) return;

    setPrevBtnEnabled(embla.canScrollPrev());
    setNextBtnEnabled(embla.canScrollNext());
  }, [embla]);

  useEffect(() => {
    if (!embla) return;
    onSelect();

    embla.on("select", onSelect);
  }, [embla, onSelect]);

  return (
    <Styled.Gallery>
      <Styled.GalleryHead>
        {title && (
          <Typography as="h2" variant="text4">
            {title}
          </Typography>
        )}
        <Styled.GalleryNavigator>
          <SliderNavigation
            prevClick={scrollPrev}
            nextClick={scrollNext}
            prevEnabled={prevBtnEnabled}
            nextEnabled={nextBtnEnabled}
          />
        </Styled.GalleryNavigator>
      </Styled.GalleryHead>
      <SRLWrapper options={LIGHTBOX_OPTIONS}>
        <Styled.GalleryBody>
          <Styled.GalleryBodyViewport ref={viewportRef}>
            <Styled.GalleryList>
              {items.map(({ id, src_small, author }) => (
                <Styled.GalleryListItem key={id} href={src_small}>
                  <Styled.GalleryListItemImage
                    alt={author}
                    src={src_small}
                  />
                </Styled.GalleryListItem>
              ))}
            </Styled.GalleryList>
          </Styled.GalleryBodyViewport>
        </Styled.GalleryBody>
      </SRLWrapper>
    </Styled.Gallery>
  );
};

export default Gallery;
