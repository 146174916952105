import styled from "styled-components";

export const CookieBar = styled.div`
  .CookieConsent {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: ${({ theme }) => theme.colors.white};
    max-width: ${({ theme }) => theme.sizes.inner};
    width: 100%;
    padding: 0 ${({ theme }) => theme.sizes.offset};
  }

  .cookiesConsent__content {
    padding: ${({ theme }) => theme.sizes.offset_big};
    width: 100%;
    background-color: ${({ theme }) => theme.colors.black};
  }

  .cookiesConsent__buttons {
    background-color: ${({ theme }) => theme.colors.black};
    padding: 0 ${({ theme }) => theme.sizes.offset_big}
      ${({ theme }) => theme.sizes.offset_big};
  }

  .cookieConsent__button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 0.75rem;
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    font-family: ${({ theme }) => theme.fonts.archia};
    height: 2.8125rem;
    border: 1px solid;
    padding: 1rem 2.375rem;
    outline: none;
    transition: ${({ theme }) => theme.transitions.default};
    background: transparent;
    color: ${({ theme }) => theme.colors.white};
    border-color: ${({ theme }) => theme.colors.white};
    cursor: pointer;
  }
`;

export const CookieBarContent = styled.div``;

export const CookieBarBody = styled.div`
  line-height: 1.5;
  font-family: ${({ theme }) => theme.fonts.archia};
  a {
    color: ${({ theme }) => theme.colors.red};
    text-decoration: none;
  }

  @media (orientation: landscape) and (max-width: 37.5rem) {
    font-size: 0.875rem;
  }
`;

export const CookieBarHead = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2rem;
`;

export const CookieBarClose = styled.div`
  svg {
    width: 0.875rem;
    height: 0.875rem;
  }
`;
