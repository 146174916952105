import { useMemo } from "react";

import * as Styled from "./styles";

const Footer = () => {
  const year = useMemo(() => new Date().getFullYear(), []);

  return (
    <Styled.Footer id={"footer"}>
      <Styled.FooterDescription>
        © {year} Teatr Dramatyczny im. Aleksandra Węgierki w
        Białymstoku. All Rights Reserved.
        <br />
        <a
          rel="noreferrer"
          href="https://dramatyczny.pl/polityka-prywatnosci/"
          target="_blank"
        >
          Polityka prywatności
        </a>
      </Styled.FooterDescription>
    </Styled.Footer>
  );
};

export default Footer;
