import { PrevButton, NextButton } from "../SliderButtons";

import { SliderPaginationProps } from "./types";
import * as Styled from "./styles";

const SliderNavigation: React.FC<SliderPaginationProps> = ({
  prevClick,
  nextClick,
  prevEnabled,
  nextEnabled
}) => (
  <Styled.SliderNavigation>
    <Styled.SliderNavigationItem>
      <PrevButton onClick={prevClick} enabled={prevEnabled} />
    </Styled.SliderNavigationItem>
    <Styled.SliderNavigationItem>
      <NextButton onClick={nextClick} enabled={nextEnabled} />
    </Styled.SliderNavigationItem>
  </Styled.SliderNavigation>
);

export default SliderNavigation;
