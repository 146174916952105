import styled from "styled-components";

import Typography from "../styles/Typography";

export const HeroSlideBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.black};
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey3};
  color: ${({ theme }) => theme.colors.white};
  height: ${({ theme }) => theme.sizes.heroHeight};
`;

export const HeroSlideBoxBg = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  opacity: 1;
  background-color: ${({ theme }) => theme.colors.grey};
  background-repeat: no-repeat;
  background-position: 50% 0%;
  background-size: cover;
  opacity: 0.25;
`;

export const HeroSlideBoxMain = styled.div`
  position: relative;
  padding: 2rem ${({ theme }) => theme.sizes.offset} 0;
  flex: 1;
  z-index: 2;
`;

export const HeroSlideBoxMainContainer = styled.div`
  max-height: calc(${({ theme }) => theme.sizes.heroHeight} / 1.6);
  overflow: hidden;
`;

export const HeroSlideBoxPrefix = styled.div`
  line-height: 1.2;
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  font-family: ${({ theme }) => theme.fonts.archia};
  margin-bottom: 1rem;
  strong {
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  }
`;

export const HeroSlideBoxTitle = styled(Typography).attrs({
  variant: "heading2",
  forwardedAs: "h2"
})`
  margin-bottom: 0.5rem;
`;

export const HeroSlideBoxAuthor = styled.div`
  margin-bottom: 4rem;
`;
