import Typography from "../styles/Typography";

import * as Styled from "./styles";
import { HeroSlideBoxProps } from "./types";

const HeroSlideBox: React.FC<HeroSlideBoxProps> = ({
  prefix,
  title,
  description,
  thumbnail
}) => (
  <Styled.HeroSlideBox>
    <Styled.HeroSlideBoxBg
      style={{
        backgroundImage: `url(${thumbnail})`
      }}
    />
    <Styled.HeroSlideBoxMain>
      <Styled.HeroSlideBoxMainContainer>
        {prefix && (
          <Styled.HeroSlideBoxPrefix
            dangerouslySetInnerHTML={{
              __html: prefix
            }}
          />
        )}
        <Styled.HeroSlideBoxTitle>{title}</Styled.HeroSlideBoxTitle>
        {description && (
          <Styled.HeroSlideBoxAuthor>
            <Typography variant="text2" as="span">
              {description.prefix}
            </Typography>{" "}
            <Typography variant="heading3" as="span">
              {description.value}
            </Typography>
          </Styled.HeroSlideBoxAuthor>
        )}
      </Styled.HeroSlideBoxMainContainer>
    </Styled.HeroSlideBoxMain>
  </Styled.HeroSlideBox>
);

export default HeroSlideBox;
