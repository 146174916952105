import styled from "styled-components";

import Typography from "../styles/Typography";

export const SpectacleItem = styled.div`
  position: relative;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.black};
  flex-grow: 1;
`;

export const SpectacleItemBg = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.5;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
`;

export const SpectacleItemContent = styled.div`
  position: relative;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  width: 100%;
  min-height: 17.5rem;
  padding: 3.5rem 1rem 1.875rem;
  align-items: flex-start;
  color: ${({ theme }) => theme.colors.white};
`;

export const SpectacleItemTitle = styled(Typography).attrs({
  variant: "heading4",
  forwardedAs: "h2"
})`
  margin-bottom: 0.25rem;
`;

export const SpectacleItemDescription = styled.div``;

export const SpectacleItemCta = styled.div`
  margin-top: auto;
  padding-top: 1.5rem;
`;
